.navigation {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    background: rgb(220,220,230);
    // background: linear-gradient(90deg, rgba(220,220,230,1) 24%, rgba(0,0,0,1) 87%, rgba(22,21,21,1) 100%);
  
    .logo-container {
      height: 100%;
      width: 70px;
      padding: 25px;
    }
  
    .nav-links-container {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
  
      .nav-link {
        padding: 10px 15px;
        cursor: pointer;
      }
    }
  }