.product-card-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 370px;
    align-items: center;
    position: relative;
    border-radius: 2%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  
    img {
      width: 100%;
      height: 70%;
      object-fit: contain;
      padding: 2rem;
      margin-bottom: 5px;
    }
  
    button {
      width: 80%;
      opacity: 0.7;
      position: absolute;
      top: 255px;
      display: none;
    }
  
    &:hover {
      img {
        opacity: 0.8;
      }
  
      button {
        opacity: 0.85;
        display: flex;
      }
    }

    .price {
        font-size: 18px;
        padding: 0.5rem;

      }

    .header {

        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    
        .name {
            padding: 0.5rem;
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

    }

   
  
    .footer {
      width: 100%;
      height: 5%;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
  
      .category {
        font-weight: lighter;
        padding: 1rem;
      }
    }
  }