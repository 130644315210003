.checkout-item-container {
  width: 100%;
  height: 15vh;
  display: flex;
  min-height: 100px;
  border-bottom: 1px solid darkgrey;
  padding: 15px 0;
  font-size: 14px;
  align-items: center;

  .image-container {
    width: 23%;
    padding-right: 15px;

    img {
      width: 30%;    }
  }
  .name,
  .quantity,
  .price {
    width: 23%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  
  }

  .quantity {
    display: flex;

    .arrow {
      cursor: pointer;
    }

    .value {
      margin: 0 10px;
    }
  }

  .remove-button {
    padding-left: 12px;
    cursor: pointer;
  }
}